export const invoice = {
    namespaced: true,
    state: {
        currentInvoiceId: "",
        currentInvoiceStep: 1,
        // This field is used to indicate that a change occured in relation to the invoices (e.g. step completed)
        invoicesState: 0
    },
    mutations: {
        setCurrentInvoiceId(state: any, id: string): void {
            state.currentInvoiceId = id;
        },
        setCurrentInvoiceStep(state: any, step: number): void {
            state.currentInvoiceStep = step;
        },
        emitStateChange(state: any): void {
            state.invoicesState += 1;
        }
    },
    actions: {
        updateCurrentInvoiceId(context: any, newId: string) {
            context.commit("setCurrentInvoiceId", newId)
        },
        updateCurrentInvoiceStep(context: any, newStep: number) {
            context.commit("setCurrentInvoiceStep", newStep)
        }
    },
    getters: {
        currentInvoiceId(state: any): string { return state.currentInvoiceId },
        currentInvoiceStep(state: any): number { return state.currentInvoiceStep },
    }
}