export default {
    en: {
        error: {
            duplicateInvoiceFile: "Duplicate invoice file",
            duplicateInvoiceOcr: "Duplicate invoice file (OCR)",
            ciIsBlocked: "Cannot be modified once the contract has been generated",
            invalidUser: "User changed unexpectedly. Please refresh the page.",
        },
    },
    fr: {
        error: {
            duplicateInvoiceFile: "Fichier facture CRT en double!",
            duplicateInvoiceOcr: "Fichier facture CRT en double! (OCR)",
            ciIsBlocked: "Ne peut pas être modifié une fois que le contrat ait été généré",
            invalidUser: "Changement d'utilisateur inattendu. Veuillez rafraîchir la page",
        },
    }
}