



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  i18n: {
    messages: {
      en: {
        title: "Help",
        close: "Close",
        helpText:
          "If you have any questions or require assistance, \
          please contact <a href='mailto:info@indemnisationtr.fr'\
          >info@indemnisationtr.fr</a>",
      },
      fr: {
        title: "Aide",
        close: "Fermer",
        helpText:
          "Si vous avez des questions ou si vous avez besoin d'aide, \
          veuillez contacter <a href='mailto:info@indemnisationtr.fr'>\
          info@indemnisationtr.fr</a>",
      },
    },
  },
})
export default class HelpDialog extends Vue {
  @Prop({ type: Boolean, required: true }) show;
}
