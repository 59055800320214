export default {
    en: {
        ci: {
            sirenNumber: "SIREN number",
            companyName: "Company name",
            brandName: "Brand name",
            legalForm: "Legal Form",
            legalFormOther: "Please specify the legal form",
            sirenFormat: "9 digit number",
            signatoryName: "Signatory name",
            signatoryFirstName: "Signatory first name",
            signatoryLastName: "Signatory last name",
            signatoryEmail: "Signatory email",
            signatoryPhoneNumber: "Phone number",
            address: "Street Address",
            addressHint: "Street number and name (example: 52 rue du Port)",
            city: "Municipality",
            cityHint: "Name of municipality (example: Nantes)",
            rcsCity: "Registration city",
            rcsCityHint: "City name (example: Paris)",
            postalCode: "Postal Code",
            postalCodeFormat: "5 digit number",
            isSoleSignatoryPosition: "The signatory above is {position} of the company",
            signatorySpecifyPosition: "If the signatory above is not {position}, please indicate the position:",
            signatoryPosition: "Signatory position",
            isAuthorizedSignSolely: "The above representative is authorized to represent the company with his signature only.",
            nameOnKbis: "The signatory's name appears on the Kbis",
            nameNotOnKbis: "The signatory's name does not appear on the Kbis and a power of attorney document is required.",
            swiftCode: "SWIFT/BIC code",
            swiftCodeConfirm: "Confirm SWIFT/BIC code",
            swiftFormat: "8 or 11 characters",
            swiftFormatMatchError: "SWIFT/BIC codes do not match",
            ibanNumber: "IBAN",
            ibanFormat: "You have entered an invalid IBAN",
            completeBankInfo: "Complete bank information",
            bankInfo: "Bank information",
            bankInfoDialogTitle: "Step 4: Bank information",
            bankInfoPrompt: `Thank you! You have just signed your contract with TransAtlantis. We thank you for your trust, and are proud to have you among us:\
                as more people join us, we are more likely to get fair compensation for each of you.<br/><br/>\
                You will receive your final contract, signed by TransAtlantis, in your mailbox shortly. It may take several days, or even more than a week.\
                Remember to check your spam regularly.<br/><br/>\
                We now ask you to enter your bank details, so that TransAtlantis can make a first transfer in the amount of 10 (ten) euros,\
                to thank you for joining our action. This first payment is not to be confused with compensation for the damage suffered as described in the contract,\
                which will depend on the outcome of the legal action.`,
            bankInfoStepInstructions: `
                Please enter the IBAN of the bank account of your company.
                Once a valid IBAN is provided, the platform will calculate the SWIFT/BIC code automatically.
                Please confirm that the SWIFT/BIC code matches the bank account of your company.`,
            editCi: "Edit Corporate Identity",
            corporateIdentity: "Corporate Identity",
            pickerTitle: "Choose corporate identity",
            dialogTitle: "Step 1: Corporate Identity",
            ciGeneralInfo: "Legal information",
            unknownCrt: "Unknown CRT number: ",
            createNew: "Enter the information of a new coporate identity",
            newCi: "New corporate identity",
            restoHasNoCi: "Please click the button to define the corporate identity",
            id: "ID",
            idInstructions: "Please upload your ID.",
            idInstructionsNote: "Note: A current ID of the person authorized to represent the company is required. " +
                "It can be a passport, a national identity card or a driver's license.",
            kbis: "Kbis",
            kbisInstructions1: `Please upload a <u>valid</u> Kbis extract of your company. To be valid, the Kbis extract must be <strong><u>less than three months</u></strong>.\
                If you do not have a valid Kbis extract, you can easily get it on the site \
                <a href='https://www.infogreffe.fr/documents-officiels/demande-kbis.html' target='_blank'>Infogreffe</a>.`,
            kbisInstructions2: "Make sure there is a verification code at the top of your Kbis extract.",
            kbisInstructions3: `Once you have your valid Kbis extract, please click on the button\
                "Select the Kbis extract" to transfer it to the platform. When the upload is complete,\
                click the UPLOAD button.`,
            kbisInstructions4: `The platform will then automatically process your document to extract the\
                relevant information about your company.`,
            kbisInstructions5: `<u>Note</u>: It is possible to skip this step, if it is really impossible for you to obtain an up to date \
                Kbis extract for your company. However, you will be required to manually enter all the information relating to \
                your company, which we will then need to verify. This is why we strongly encourage you to upload a Kbis extract.`,
            invalidKbis: "(Invalid Kbis extract)",
            blocked: "Corporate identity cannot by modified once the contract has been generated",
            resetCi1: `You may click the button below to discard this Kbis and upload another one.
                Please note that you may obtain a valid Kbis extract of your company
                on <a href='https://www.infogreffe.fr/documents-officiels/demande-kbis.html' target='_blank'>infogreffe</a>
                or <a href='https://monidenum.fr/' target='_blank'>MonIdenum</a>.`,
            resetCi2: "Please make sure the Kbis extract has a verification code at the top.",
            addSignatory: "Add Signatory",
            regenerateContract: "Regenerate contract",
            regeneratedContract: "Contract regenerated.",
            emailSignMessage: "Please sign the contract",
            emailSignContract: "Sign Contract",
            bccHelpDesk: "BCC Help Desk Mailbox",
            emailBody: "Email Body",
            emailSubject: "Email Subject",
            sendEmail: "Send Email",
            emailDialogSubtitle: "Send an email to clients informing them  of their regenerated contract",
            emailDefaultSubject: "Meal-Voucher Contract",
            uploadKbisForExistingCi: "Upload Kbis for existing company",
            uploadManagingEntityKbis: "Upload Managing Entity Kbis",
            earningsEstimationInMV: "Estimated annual turnover in meal vouchers (non-contractual data, for information purposes only)",
            ribInstructions: "Please upload a valid RIB document.",
            optionalInfo: "Optional information",
        },
        error: {
            signatoryEmailExists: "A signatory with that email already exists!",
        }
    },
    fr: {
        ci: {
            sirenNumber: "Numéro SIREN",
            companyName: "Dénomination",
            brandName: "Nom Enseigne",
            legalForm: "Forme Juridique",
            legalFormOther: "Veuillez préciser la forme juridique",
            sirenFormat: "Nombre à 9 chiffres",
            signatoryName: "Nom du représentant",
            signatoryFirstName: "Prénom du représentant",
            signatoryLastName: "Nom de famille du représentant",
            signatoryEmail: "Adresse Email du représentant",
            signatoryPhoneNumber: "Numéro de téléphone",
            address: "Adresse du siège",
            addressHint: "Numéro et voie (exemple : 52 rue du Port)",
            city: "Commune",
            cityHint: "Nom de la commune (exemple : Nantes)",
            rcsCity: "Ville d'immatriculation",
            rcsCityHint: "Nom de la ville d'immatriculation (exemple : Paris)",
            postalCode: "Code Postal",
            postalCodeFormat: "Nombre à 5 chiffres",
            isSoleSignatoryPosition: "Le représentant ci-dessus est {position} de l’entreprise",
            signatorySpecifyPosition: "Si le représentant n'est pas {position}, veuillez préciser la fonction :",
            signatoryPosition: "Fonction du représentant",
            isAuthorizedSignSolely: "Le représentant ci-dessus est autorisé à représenter l’entreprise avec sa seule signature.",
            nameOnKbis: "Le nom du représentant figure sur le Kbis",
            nameNotOnKbis: "Le nom du représentant ne figure pas sur le kbis, et un justificatif est requis",
            swiftCode: "Code SWIFT / BIC",
            swiftCodeConfirm: "Confirmer le code SWIFT / BIC",
            swiftFormat: "8 ou 11 caractères",
            swiftFormatMatchError: "Les codes SWIFT / BIC ne correspondent pas",
            ibanNumber: "IBAN",
            ibanFormat: "Vous avez entré un IBAN invalide",
            completeBankInfo: "Compléter les informations bancaires",
            bankInfo: "Informations bancaires",
            bankInfoDialogTitle: "Étape 4 : Informations bancaires",
            bankInfoPrompt: `Merci ! Vous venez de signer votre contrat avec TransAtlantis. Nous vous remercions pour votre confiance, et sommes fiers de vous compter parmi nous :\
                plus nous serons nombreux, plus nous aurons de chance d’obtenir une juste compensation pour chacun d’entre vous.<br><br>\

                Vous recevrez votre contrat définitif, signé par TransAtlantis, dans votre boite mail sous peu. Cela peut prendre plusieurs jours, voire plus d’une semaine.\
                N’oubliez pas de vérifier vos spams régulièrement.<br><br>\

                Nous vous demandons maintenant de renseigner vos informations bancaires, afin que TransAtlantis puisse procéder à un premier virement d’un montant de 10 (dix) euros,\
                afin de vous remercier d’avoir rejoint notre action. Ce premier versement n’est pas à confondre avec le dédommagement du préjudice subi tel que décrit dans le contrat,\
                qui dépendra lui de l’issue de l’action judiciaire.`,
            bankInfoStepInstructions: `
                Veuillez fournir l’IBAN du compte bancaire de votre entreprise.
                Une fois qu'un IBAN valide est fourni, la plateforme calcule automatiquement le code SWIFT / BIC.
                Veuillez confirmer que le code SWIFT / BIC correspond au compte bancaire de votre entreprise.`,
            editCi: "Modifier Responsable légal du restaurant",
            corporateIdentity: "Responsable légal du restaurant",
            pickerTitle: "Choisir un responsable légal du restaurant",
            dialogTitle: "Étape 1 : Informations concernant votre entreprise",
            ciGeneralInfo: "Informations légales",
            unknownCrt: "Numéro CRT inconnu: ",
            createNew: "Fournir les informations sur le responsable légal",
            newCi: "Nouveau responsable légal",
            restoHasNoCi: "Veuillez cliquer sur le bouton pour définir responsable légal",
            id: "Pièce d'identité",
            idInstructions: "Veuillez fournir une copie de votre pièce d'identité.",
            idInstructionsNote: "Remarque : une pièce d'identité à jour de la personne habilitée à représenter l'entreprise est nécessaire. " +
                "Il peut s'agir d'un passeport, d'une carte nationale d'identité ou d'un permis de conduire.",
            kbis: "Kbis",
            kbisInstructions1: `Veuillez transférer un extrait <u>valide</u> du Kbis de votre entreprise. Pour être valide, ce dernier doit avoir <strong><u>moins de trois mois</u></strong>.\
                Si vous ne possédez pas d’extrait de Kbis valide, vous pouvez vous le procurer facilement sur le site\
                <a href='https://www.infogreffe.fr/documents-officiels/demande-kbis.html' target='_blank'>Infogreffe</a>.`,
            kbisInstructions2: "Attention ! Assurez-vous qu’un code de vérification figure bien en haut de votre extrait Kbis.",
            kbisInstructions3: `Une fois que vous êtes en possession de votre extrait Kbis valide, veuillez cliquer sur le bouton \
                « sélectionner l’extrait Kbis » pour transférer ce dernier sur la plateforme. Lorsque le téléchargement est terminé,\
                appuyez sur le bouton transférer.`,
            kbisInstructions4: `La plateforme traitera ensuite automatiquement votre document pour en extraire les\
                informations pertinentes relatives à votre entreprise.`,
            kbisInstructions5: `<u>Remarque</u> : il est possible de passer cette étape, s’il vous est impossible d’obtenir un extrait\
                Kbis à jour pour votre entreprise. Néanmoins vous serez contraints de saisir manuellement toutes les informations relatives à\
                votre société, que nous devrons ensuite vérifier. C’est pourquoi nous vous encourageons vivement à télécharger un extrait Kbis. `,
            invalidKbis: "(Extrait Kbis invalide)",
            blocked: "Le responsable légal du restaurant ne peut pas être modifiée une fois que le contrat ait été généré",
            resetCi1: `Vous pouvez cliquer sur le bouton ci-dessous pour supprimer cet extrait Kbis et en transférer un autre.
                Veuillez noter que vous pouvez obtenir un extrait Kbis valide de votre entreprise
                sur <a href='https://www.infogreffe.fr/documents-officiels/demande-kbis.html' target='_blank'>infogreffe</a>
                ou sur <a href='https://monidenum.fr/' target='_blank'>MonIdenum</a>.`,
            resetCi2: "Merci de vous assurer qu'en haut de l'extrait Kbis figure un code de vérification.",
            addSignatory: "Ajouter un signataire",
            regenerateContract: "Regénérer le contract",
            regeneratedContract: "Le contract a été regénéré",
            emailSignMessage: "Veuillez signer le contrat",
            emailSignContract: "Signature du contrat",
            bccHelpDesk: "Mettre en copie cachée le mail du Help Desk",
            emailBody: "Corps de l'e-mail",
            emailSubject: "Sujet de l’e-mail",
            sendEmail: "Envoyer un e-mail",
            emailDialogSubtitle: "Envoyer un e-mail aux clients les informant du renouvellement de leur contrat",
            emailDefaultSubject: "Contrat Tickets-Restaurant",
            uploadKbisForExistingCi: "Transférer un Kbis pour une entreprise existante",
            uploadManagingEntityKbis: "Transférer le Kbis de l'entité de gestion",
            optionalInfo: "Informations facultatives",
            earningsEstimationInMV: "Estimation du chiffre d’affaires annuel en titres-restaurant (donnée non contractuelle, uniquement à titre indicatif)",
            ribInstructions: "Veuillez transférer le document « Relevé d’Identité Bancaire »"
        },
        error: {
            signatoryEmailExists: "Un signataire avec cet e-mail existe déjà!",
        }
    }
}