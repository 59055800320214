




















import { Component, Vue } from "vue-property-decorator";
import { UserInfo } from "../models/userInfo";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      language: "locale/language",
      languages: "locale/languages",
    }),
  },
})
export default class Language extends Vue {
  mounted() {
    this.$store.getters["userInfo/get"].then((info: UserInfo) => {
      if (info.language != undefined) {
        this.updateLocale(info.language);
      }
    });
  }

  get currentLanguageName() {
    return this.languages.filter((x) => x.value === this.$i18n.locale)[0].text;
  }

  updateLocale(language) {
    this.$store.dispatch("locale/updateLanguage", {
      language: language,
    });
    // Unfortunately this cannot be done from the store
    this.$i18n.locale = language;
    this.$moment.locale(language);
  }
}
