





























import { Component, Prop, Vue } from "vue-property-decorator";
import navMessages from "../locales/nav";
import HelpDialog from "./HelpDialog.vue";

@Component({
  components: {
    HelpDialog,
  },
  i18n: {
    messages: {
      en: {
        ...navMessages.en,
      },
      fr: {
        ...navMessages.fr,
      },
    },
  },
})
export default class HelpNavBtn extends Vue {
  @Prop({ required: false, default: false, type: Boolean }) asListItem;

  showDialog = false;
}
