











import { Component, Vue } from "vue-property-decorator";
import restaurantMessages from "../locales/restaurant";
import uploadMessages from "../locales/upload";
import ciMessages from "../locales/ci";

@Component({
  i18n: {
    messages: {
      en: {
        ...restaurantMessages.en,
        ...uploadMessages.en,
        ...ciMessages.en,
        message: {
          close: "close",
          confirmation: "Completed successfuly",
        },
      },
      fr: {
        ...restaurantMessages.fr,
        ...uploadMessages.fr,
        ...ciMessages.fr,
        message: {
          close: "fermer",
          confirmation: "Complété avec succès",
        },
      },
    },
  },
})
export default class App extends Vue {
  public text = "";
  public show = false;

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "message/showMessage") {
        this.text = this.$t(this.$store.getters["message/text"]).toString();
        this.show = true;
      }
    });
  }
}
