export default {
    en: {
        nav: {
            title: "Meal Voucher Platform",
            home: "Home",
            help: "Help",
            account: "Account",
            communications: "Communications",
            claimsInfo: "Claims info"
        },
    },
    fr: {
        nav: {
            title: "Plateforme Titres-Restaurant",
            home: "Accueil",
            help: "Aide",
            account: "Profil",
            claimsInfo: "Information Contrat",
            communications: "Messagerie"
        },
    }
}