export class LoginInfo {
    public email = "";
    public password = "";
    public rememberMe = false;
    public returnUrl = null;
}

export class changePassInfo {
    public oldPassword = "";
    public newPassword = "";
    public confirmNewPassword = "";
}

export class forgotPassInfo {
    public email = "";
}

export class ResetPassInfo {
    public email = "";
    public token = "";
    public newPassword = "";
    public confirmNewPassword = ""
}
