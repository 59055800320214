import { UserInfo } from "../../models/userInfo"
import axios from "axios";

export const userInfo = {
    namespaced: true,
    state: {
        userInfo: null,
        impersonatedUserInfo: null,
    },
    mutations: {
        reset(state: any): void {
            state.userInfo = null;
            state.impersonatedUserInfo = null;
        },
    },
    getters: {
        get(state: any): Promise<UserInfo> {
            if (state.userInfo == null) {
                return axios.get("/User/GetMyUserInfo").then(resp => {
                    return resp.data;
                });
            }

            return new Promise(() => state.userInfo);
        },
        getImpersonated(state: any): Promise<UserInfo> {
            if (state.impersonatedUserInfo == null) {
                return axios.get("/User/GetMyUserInfo", {
                    params: { impersonatedUser: true },
                }).then(resp => {
                    state.impersonatedUserInfo = resp.data;
                    return resp.data;
                });
            }

            return new Promise(() => state.impersonatedUserInfo);
        },
    }
}