export const communication = {
    namespaced: true,
    state: {
        unreadCount: 0,
    },
    mutations: {
        setUnreadCount(state: any, count: number): void {
            state.unreadCount = count;
        },
        decrementUnreadCount(state: any): void {
            state.unreadCount -= 1;
        },
    },
    getters: {
        unreadCount(state: any): number { return state.unreadCount }
    }
}