export default {
    en: {
        login: {
            language: "Langue",
            header: "Sign in",
            forgotPassword: "Forgot your password?",
            rememberMe: "Remember me",
            email: "Email address",
            password: "Password",
            message: "Welcome to TransAtlantis Meal Voucher action platform",
            invalidCredentials: "Invalid username/password combination",
        },
    },
    fr: {
        login: {
            language: "Language",
            header: "Connexion",
            forgotPassword: "Mot de passe oublié ?",
            rememberMe: "Se souvenir de moi",
            email: "Adresse email",
            password: "Mot de passe",
            message: "Bienvenue sur la plateforme de l'action titres-restaurant",
            invalidCredentials: "Combinaison de nom d'utilisateur et de mot de passe invalide",
        },
    }
}