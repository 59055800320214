export default {
    en: {
        upload: {
            file: "Select invoices",
            contract: "Select contracts",
            kbis: "Select the Kbis extract",
            id: "Select a copy of your ID",
            otherDocuments: "Select documents",
            processing: "Processing document...",
            pending: "Document is queued...",
            failed: "Document processing failed",
            maxFileSize: "Maximum file size is",
            megabyte: "MB",
            upload: "Upload",
            uploadConfirmation: "The document has been uploaded successfully.",
            pleaseWaitKbis: "Please wait while we are verifying the Kbis extract. This will take about 30 seconds.",
            allowedFileTypes: "Only PDF, JPG, PNG and TIFF files are allowed",
            rib: "Select bank RIB document"
        },
    },
    fr: {
        upload: {
            file: "Sélectionner les factures CRT",
            contract: "Sélectionner contrat",
            kbis: "Sélectionner l'extrait Kbis",
            id: "Sélectionner une copie de votre pièce d'identité",
            otherDocuments: "Sélectionner le(s) document(s)",
            processing: "Document en cours de traitement...",
            pending: "Document en cours de traitement...",
            failed: "Erreur de traitement",
            maxFileSize: "La taille maximale d'un document est",
            megabyte: "Mo",
            upload: "Transférer",
            uploadConfirmation: "Le document a été transféré avec succès.",
            pleaseWaitKbis: "Veuillez patienter pendant que nous vérifions l'extrait Kbis. Cela prendra environ 30 secondes.",
            allowedFileTypes: "Seulement les fichiers PDF, JPG, PNG et TIFF sont acceptés",
            rib: "Selectionner le document RIB"
        },
    }
}