import axios from "axios";

export const survey = {
    namespaced: true,
    state: {
        doSurvey: false,
        survey: null
    },
    mutations: {
        setSurvey(state: any, survey: Object): void {
            state.survey = survey;
        },
        setDoSurvey(state: any, doSurvey: boolean): void {
            state.doSurvey = doSurvey;
        }
    },
    actions: {
        updateSurveyStatus(context: any) {
            return axios.get("/Survey/GetSurvey").then((resp) => {
                if (resp.data.length > 0) {
                    context.commit('setSurvey', resp.data[0]);
                } else {
                    context.commit('setSurvey', null);
                }
            });
        },
        updateDoSurvey(context: any) {
            if (context.getters.surveyId === null) {
                context.commit('setDoSurvey', true);
            } else {
                context.commit('setDoSurvey', false);
            }
        },
        updateSurvey(context: any) {
            context.dispatch('updateSurveyStatus').then(() => {
                context.dispatch('updateDoSurvey');
            });
        }
    },
    getters: {
        doSurvey(state: any): boolean { return state.doSurvey },
        surveyId(state: any): string { return (state.survey != null ? state.survey.id : null) }
    }
}