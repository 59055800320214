export const ci = {
    namespaced: true,
    state: {
        currentCrtNumber: "",
    },
    mutations: {
        setCurrentCrtNumber(state: any, num: string): void {
            state.currentCrtNumber = num;
        },
    },
    getters: {
        currentCrtNumber(state: any): string { return state.currentCrtNumber }
    }
}