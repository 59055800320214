import { Locales } from "../../locales/locales";
import axios from "axios";
import Vue from 'vue'
import VueI18n from 'vue-i18n';

export const locale = {
    namespaced: true,
    state: {
        language: "fr",
        languages: Locales,
    },
    mutations: {
        setLanguage(state: any, language: string): void {
            state.language = language;
        },
    },
    actions: {
        async updateLanguage({ commit }: { commit: Function }, payload: any): Promise<any> {
            var language: string = payload["language"];
            commit('setLanguage', language);
            return axios
                .post("/User/SetLanguage", null, {
                    params: { language: language },
                })
        },
    },
    getters: {
        language(state: any): string { return state.language },
        languages(state: any): string { return state.languages }
    }
}