

















































import { Component, Vue } from "vue-property-decorator";
import Language from "./components/Language.vue";
import BottomMessage from "./components/BottomMessage.vue";
import HelpNavBtn from "./components/HelpNavBtn.vue";
import axios from "axios";
import navMessages from "./locales/nav";

import logo from "./assets/TransAtlantis_Logo.png";

@Component({
  components: {
    Language,
    HelpNavBtn,
    BottomMessage,
  },
  i18n: {
    messages: navMessages,
  },
})
export default class IdentityHeader extends Vue {
  logo = logo;
  private authenticated: Boolean = false;
  mounted() {
    axios.get("/Home/IsAuthenticated").then((response) => {
      this.authenticated = response.data;
    });
  }
}
