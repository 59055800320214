import errorMessages from "./error"

export default {
    en: {
        ...errorMessages.en,

        yes: "Yes",
        no: "No",
        start: "Start",
        submit: "Submit",
        continue: "Continue",
        back: "Back",
        complete: "Complete",
        completeLater: "SKip and complete later",
        confirm: "Confirm",
        edit: "Edit",
        download: "Download",
        view: "View",
        finalize: "Finalize",
        validate: "Validate",
        logout: "Logout",
        dateFormat: "DD/MM/YYYY",
        help: "Help",
        reset: "Reset",
        resume: "Resume",
        save: "Save",
        login: "Login",
        closeDialog: "Step completed, you can now close this dialog."
    },
    fr: {
        ...errorMessages.fr,

        yes: "Oui",
        no: "Non",
        start: "Commencer",
        submit: "Soumettre",
        continue: "Continuer",
        back: "Retour",
        complete: "Terminer",
        completeLater: "Remplir plus tard",
        confirm: "Confirmer",
        edit: "Modifier",
        download: "Télécharger",
        view: "Consulter",
        finalize: "Finaliser",
        validate: "Valider",
        logout: "Se déconnecter",
        dateFormat: "JJ/MM/AAAA",
        help: "Aide",
        reset: "Réinitialiser",
        resume: "Continuer",
        save: "Enregistrer",
        login: "Connexion",
        closeDialog: "Étape terminée, vous pouvez maintenant fermer ce dialogue."
    },
};