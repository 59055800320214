import Vue from "vue"
import Vuex from "vuex"
import { ci } from "./modules/CorporateIdentityModule"
import { invoice } from "./modules/InvoiceModule"
import { bottomMessage } from "./modules/BottomMessageModule"
import { communication } from "./modules/CommunicationModule"
import { userInfo } from './modules/UserInfoModule'
import { survey } from './modules/SurveyModule'
import { locale } from './modules/LocaleModule'
import VueI18n from 'vue-i18n';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ci: ci,
    invoice: invoice,
    message: bottomMessage,
    communication: communication,
    survey: survey,
    userInfo: userInfo,
    locale: locale,
  },
})
