








import { Component, Vue, Prop } from "vue-property-decorator";
import VueI18n from "vue-i18n";
import * as Bowser from "bowser";

@Component({
  i18n: {
    messages: {
      en: {
        unsupportedBrowser: {
          alertMessage:
            "We highly recommend that you use Google Chrome or Safari to access our website. Other browsers such as Internet Explorer or the " +
            "legacy version of Microsoft Edge are not supported. If you intend to use Microsoft Edge, please make sure you have the updated " +
            "version (version number > 79).",
        },
      },
      fr: {
        unsupportedBrowser: {
          alertMessage:
            "Nous vous recommandons vivement d'utiliser Google Chrome ou Safari pour accéder à notre site web. Les autres navigateurs tels " +
            "qu'Internet Explorer ou la version ancienne de Microsoft Edge ne sont pas pris en charge. Si vous avez l'intention d'utiliser " +
            "Microsoft Edge, veuillez vous assurer que vous disposez de la version mise à jour (numéro de version > 79).",
        },
      },
    },
  },
})
export default class UnsupportedBrowserAlert extends Vue {
  get isUnsupportedBrowser() {
    const browser = Bowser.getParser(window.navigator.userAgent);

    return !browser.satisfies({
      safari: ">9",
      chrome: ">50",
      edge: ">79",
    });
  }
}
