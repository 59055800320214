export default {
    en: {
        restaurant: {
            crtRegistrationYear: "CRT registration year (optional)",
            newRestaurant: "New Restaurant",
            continueReview: `To proceed, please continue to review the restaurant information`,
            pleaseReview: `
                The platform have extracted the info of the following restaurant from your invoices.<br/>
                <b>Please review and make edits if you think there are errors.</b>`,
            saved: "Les informations du restaurant ont été enregistrés",
            editRestaurant: "Step 2: Information concerning your business",
            active: "Is the restaurant is still in operation?",
            activeNote: "Note: all restaurants, whether still in business or not, that accepted meal vouchers after 2002 are eligible for compensation.",
            name: "Restaurant name",
            crtNumber: "CRT number",
            crtError: "CRT format must be 0000000*0",
            associatedCorporateIdentity: "Associated corporate identity",
            associateCi: "Corporate identity",
            doesNotExist: "Step 2: Verify the information and provide documents concerning your restaurants and your company",
            fixInfoPrompt: "Cannot generate contract: some information is missing.",
            fixInfoButton: "Complete",
            contractNotSigned: "Step 3: Please click the button to sign a contract",
            define: "Continue",
            blocked: "Restaurant cannot by modified once the contract has been generated",
            proceedSurvey: "Proceed to Restaurant Survey",
            surveyMessage: `Thank you for signing the contract. We will appreciate it if you could answer a few more questions regarding your restaurant.
                    Your answers to those questions will not impact your compensation.`
        },
        error: {
            restoWithSameCrtNumberExists: "Restaurant with same CRT already exists!",
            invalidCi: "Invalid Corporate Identity",
            restoIsBlocked: "This restaurant is blocked",
        }
    },
    fr: {
        restaurant: {
            crtRegistrationYear: "Année d’enregistrement à la CRT (facultatif)",
            newRestaurant: "Nouveau commerce",
            continueReview: `Pour continuer, veuillez poursuivre le processus de confirmation des informations du restaurant`,
            pleaseReview: `
                La plateforme a extrait de vos factures CRT les informations relatives aux restaurant suivants.<br/>
                <b>Veuillez les vérifier et les modifier si vous pensez qu'il y a des erreurs.</b>`,
            editRestaurant: "Étape 2 : Les informations concernant votre commerce",
            active: "Est-ce que le restaurant est encore en activité ?",
            activeNote: "Remarque : tous les restaurants, encore en activité ou non, qui ont accepté des titres-restaurant après 2002 sont susceptibles de faire l'objet d'un dédommagement.",
            name: "Nom du restaurant/commerce",
            crtNumber: "Numéro CRT",
            crtError: "Le format CRT doit être 0000000*0",
            associatedCorporateIdentity: "Responsable légal du restaurant associée",
            doesNotExist: "Étape 2 : Vérification des documents et complément d’informations concernant votre(vos) restaurant(s) et votre entreprise.",
            fixInfoPrompt: "Nous ne pouvons pas générer le contrat : certaines informations sont manquantes.",
            fixInfoButton: "Compléter",
            contractNotSigned: "Étape 3 : Veuillez cliquer sur le bouton pour signer le contrat",
            define: "Continuer",
            blocked: "Le restaurant ne peut pas être modifiée une fois que le contrat ait été généré",
            proceedSurvey: "Passer à l'enquête sur les restaurants",
            surveyMessage: `Merci d'avoir signé le contrat. Nous apprécierons si vous pouviez répondre à quelques questions supplémentaires concernant votre restaurant.
                    Vos réponses à ces questions n'auront aucun impact sur votre rémunération.`
        },
        error: {
            restoWithSameCrtNumberExists: "Restaurant avec le même CRT existe déjà!",
            invalidCi: "Identité d'entreprise invalide",
            restoIsBlocked: "Ce restaurant est bloqué",
        }
    }
}