

























































































import { Component, Vue } from "vue-property-decorator";
import { LoginInfo } from "./models/identity";
import Language from "./components/Language.vue";
import UnsupportedBrowserAlert from "./components/UnsupportedBrowserAlert.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import loginMessages from "./locales/login";

@Component({
  components: {
    UnsupportedBrowserAlert,
    Language,
  },
  computed: {
    ...mapGetters({
      language: "locale/language",
    }),
  },
  i18n: {
    messages: loginMessages,
  },
})
export default class Login extends Vue {
  language: string;
  private loginModel: LoginInfo = new LoginInfo();
  private valid: Boolean = false;
  private showPassword: Boolean = false;
  private alertMessage: string = "";
  async authenticate() {
    this.alertMessage = "";
    const loginResponse = await axios.post(
      "/Identity/Account/Login",
      this.loginModel
    );
    await this.$store.dispatch("locale/updateLanguage", {
      language: this.language,
    });
    if (loginResponse.data instanceof Array) {
      this.alertMessage = loginResponse.data[0]["Description"];
    } else {
      window.location.href = loginResponse.request.responseURL;
    }
  }
}
