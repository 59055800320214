export const bottomMessage = {
    namespaced: true,
    state: {
        text: ""
    },
    mutations: {
        showMessage(state: any, text: string): void {
            state.text = text;
        }
    },
    getters: {
        text(state: any): string { return state.text }
    }
}