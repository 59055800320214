// Polyfills for IE11
import "core-js/stable"
import "regenerator-runtime/runtime"

import Vue from "vue";
import Vuex from "vuex";
import VueI18n from 'vue-i18n';
import Login from "./Login.vue";
import IdentityHeader from "./IdentityHeader.vue";
import vuetify from './plugins/vuetify';
import store from "./store";
import messages from "./locales/common";
import VueMoment from "vue-moment";
import VueRouter from "vue-router"

import Moment from "moment/min/moment-with-locales";

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueRouter)

Vue.use(VueMoment, { moment: Moment })
Vue.use(Vuex)

const i18n = new VueI18n({
    locale: 'fr',
    messages,
    silentFallbackWarn: true
})

const router = new VueRouter({
    routes: [
        { path: "/", component: Login },
    ]
})

new Vue({
    i18n,
    store,
    vuetify,
    router,
    render: h => h(IdentityHeader),
}).$mount("#identityheader")
